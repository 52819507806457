import React, { Suspense, Fragment, lazy } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import GuestGuard from "./components/helper-components/GuestGuard";
import LoadingScreen from "./components/helper-components/LoadingScreen";
import AuthGuard from "./components/helper-components/AuthGuard";
import MainContainer from "./components/Layouts/MainContainer";

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    guard: GuestGuard,
    path: "/login",
    component: lazy(() => import("./components/SignIn/SignIn")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/register",
    component: lazy(() => import("./components/Signup/Signup")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/create-organization",
    component: lazy(() => import("./components/OrgSignup/OrgSignup")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/multifactorauthentication",
    component: lazy(() =>
      import("./components/SignIn/MultifactorAuthentication")
    ),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/forgotpassword",
    component: lazy(() => import("./components/ForgotPassword/ForgotPassword")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/submitpassword",
    component: lazy(() =>
      import("./components/ForgotPassword/SubmitNewPassword")
    ),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/validateOTP",
    component: lazy(() => import("./components/ForgotPassword/ValidateOTP")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/password-recovery",
    component: lazy(() =>
      import("./components/PasswordRecovery/PasswordRecovery")
    ),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/404",
    component: lazy(() => import("./components/notFound/NotFound")),
  },
  {
    path: "/app",
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: "/app/landingdashboard/",
        component: lazy(() => import("./components/Pages/LandingDashboard")),
      },
      {
        exact: true,
        path: "/app/devices/",
        layout: MainContainer,
        component: lazy(() => import("./components/Pages/Devices2")),
      },
      {
        exact: true,
        path: "/app/collections/",
        layout: MainContainer,
        component: lazy(() =>
          import("./components/Pages/CollectionManagement")
        ),
      },
      {
        exact: true,
        path: "/app/account/",
        layout: MainContainer,
        component: lazy(() => import("./components/Pages/User")),
      },
    ],
  },

  {
    path: "*",
    routes: [
      {
        exact: true,
        path: "/",
        component: () => <Redirect to="/create-organization" />,
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

export default routes;
