import React from "react";

type Props = {
  firstName: string;
  lastName: string;
  role: string;
};

function AccountCard({ firstName, lastName, role }: Props) {
  return (
      <div
        className={`cursor-pointer flex items-center gap-2 p-3 relative hover:-translate-y-1 hover:-translate-x-1 drop-shadow-md hover:drop-shadow-lg transition-all box-shadow-md  rounded-lg bg-white`}
      >
        <div>
          <div className="inline-flex overflow-hidden relative justify-center items-center w-14 h-14 bg-amniLightBlue rounded-full dark:bg-gray-600">
            <span className="font-medium text-amniDarkBlue dark:text-gray-300">
              {firstName?.charAt(0).toUpperCase()}
              {lastName?.charAt(0)?.toUpperCase()}
            </span>
          </div>
        </div>
        <div className="text-amniDarkBlue ">
          <p className="font-extrabold text-base leading-tight">
            {firstName}&nbsp;
            {lastName?.charAt(0)?.toUpperCase() + "."}
          </p>
          <p className="text-sm leading-tight">{role}</p>
        </div>
      </div>
  );
}

export default AccountCard;
