import { PropagatedTagSource } from "aws-cdk-lib/aws-ecs";
import React from "react";

type Props = {
  header: string;
  children: React.ReactNode;
  loading: any;
  error: any;
};

const SearchItemContainer = ({ children, header, loading, error }: Props) => {
  return (
    <div className="border-b border-amniLightBlue p-4 overflow-auto">
      <h3 className="text-amniDarkBlue text-sm mb-3 font-semibold uppercase">
        {header}
      </h3>
      {loading && (
        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
      )}
      {error && (
        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
      )}
      {!loading && !error && (
        <div className={`flex gap-3 flex-wrap items-baseline`}>{children}</div>
      )}
    </div>
  );
};

export default SearchItemContainer;
