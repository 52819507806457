import { configureStore } from "@reduxjs/toolkit";
import groupSlice from "./Slices/groupSlice";
import devSlice from "./Slices/deviceSlice";
import onboardSlice from "./Slices/onboardSlice";

export const store = configureStore({
  reducer: {
    group: groupSlice,
    devices: devSlice,
    onboarding: onboardSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
