import { PayloadAction, createSlice } from "@reduxjs/toolkit";

// Define a type for the slice state
interface DevState {
  createDevModelIsActive: boolean;
}

// Define the initial state using that type
const initialState: DevState = {
  createDevModelIsActive: false,
};

export const devSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {
    toggle: (state) => {
      state.createDevModelIsActive = !state.createDevModelIsActive;
      console.log(
        "state.createDevModelIsActive toggled to",
        state.createDevModelIsActive
      );
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggle } = devSlice.actions;

export default devSlice.reducer;
