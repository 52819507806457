import { ReactComponent as Engine } from "../../../assets/amniEngine.svg";
import { ChevronRightIcon, PlusIcon, XIcon } from "@heroicons/react/solid";
import { ACCESS_TOKEN, ORG_ID } from "../../../queries/localStorage";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { toggle } from "../../../redux/Slices/deviceSlice";
import { CollectionIcon } from "@heroicons/react/outline";
import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Spinner } from "flowbite-react";
import {
  ADD_DEVICE_TO_COLLECTION,
  GET_COLLECTIONS_BY_ORGID,
} from "../../../queries/collections";
import toast from "react-hot-toast";
import {
  REGISTER_DEVICE,
  EDIT_DEVICE_BY_SERIAL,
  GET_TABLE_DATA_BY_ORG_ID,
  GET_DEVICES_BY_SELECTED_GROUP,
} from "../../../queries/devices";

type Props = {};

enum Stages {
  Register,
  AddCollection,
  Review,
}

interface Input {
  serialNum: string;
  name: string;
  id: string;
}

interface IDeviceCard {
  name?: string;
  serial?: string;
}

function ReviewDeviceCard({ name, serial, ...props }: IDeviceCard) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`p-4 mt-4 bg-amniGrey rounded-lg  w-full`}>
      <div className="flex justify-between items-center">
        <div>
          <h3 className="font-black text-xl text-amniDarkBlue">
            {name ? name : `Amni-${serial?.substring(serial.length - 4)}`}
          </h3>
          <div className="mt-2 overflow-x-auto flex justify-start items-center gap-2">
            {
              // <GroupBadge content={"School 2"} />
              // <TagBadge content={"Model1"} />
            }
          </div>
        </div>
        {/*isOpen === false ? (
          <ChevronDownIcon
            className="w-6 h-6 stroke"
            onClick={() => {
              setIsOpen(true);
            }}
          />
        ) : (
          <div className="flex gap-2">
            <button className="bg-amniBlue p-1 rounded-full">
              <PencilIcon className="w-4 h-4  stroke-white" />
            </button>
            <button className="bg-amniBlue p-1 rounded-full">
              <TrashIcon className="w-4 h-4  stroke-white" />
            </button>
            <button onClick={() => setIsOpen(false)}>
              <ChevronUpIcon className="w-6 h-6" />
            </button>
          </div>
        )*/}
      </div>
      {/*isOpen && (
        <div className="-mx-4 mt-4 -mb-4 rounded-b-lg bg-gradient-to-b from-amniGrey to-white border-t-amniLightBlue border-t">
          <div className="p-6">
            <div className="mt-4 flex justify-start items-center flex-wrap gap-4">
              <div className="flex justify-center items-center gap-2">
                <span>Active</span>
                <label
                  htmlFor="default-toggle"
                  className="inline-flex relative items-center cursor-pointer"
                >
                  <input
                    type="checkbox"
                    value=""
                    id="default-toggle"
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 bg-amniGrey peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-amniLightBlue dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-amniBlue"></div>
                </label>
              </div>
              <div className="flex justify-center items-center gap-2">
                <span>Active</span>
                <label
                  htmlFor="default-toggle"
                  className="inline-flex relative items-center cursor-pointer"
                >
                  <input
                    type="checkbox"
                    value=""
                    id="default-toggle"
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 bg-amniGrey peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-amniLightBlue dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-amniBlue"></div>
                </label>
              </div>
            </div>
          </div>
          <div className="grow pl-4 pt-4  text-amniDarkBlue">
            <table className=" border border-transparent border-spacing-6 items-center w-full bg-transparent border-collapse">
              <tbody className="">
                <tr className="">
                  <th className="p-2 text-left">DeviceId</th>
                  <td className="">54984568</td>
                </tr>
                <tr>
                  <th className="p-2 text-left">Label</th>
                  <td>Label 24</td>
                </tr>
                <tr>
                  <th className="p-2 text-left">Host IP</th>
                  <td>198.162.1.69</td>
                </tr>
                <tr>
                  <th className="p-2 text-left">Shake ID</th>
                  <td>9001</td>
                </tr>
              </tbody>
            </table>
          </div>
          { <div className="flex border-t pt-4 border-amniLightBlue justify-center my-5  items-center">
              <button
                type="button"
                className="w-5/12 text-white bg-amniDarkBlue hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              >
                Save Changes
              </button>
            </div> }
        </div>
          )*/}
    </div>
  );
}

function AddDevice({}: Props) {
  const dispatch = useAppDispatch();

  const isActive = useAppSelector(
    (state) => state.devices.createDevModelIsActive
  );
  const [stage, setStage] = useState<Stages>(Stages.Register);
  const [input, setInput] = useState<Input>({
    name: "",
    serialNum: "",
    id: "",
  });
  const [allDevices, setAllDevices] = useState<Input[] | []>([]);
  const [tag, setTag] = useState<string>("");
  const [selectedCollection, setSelectedCollection] = useState<
    { name: string; id: string }[]
  >([]);

  const [registerDevice, { data, loading, error, reset }] = useMutation(
    REGISTER_DEVICE,
    {
      refetchQueries: [GET_TABLE_DATA_BY_ORG_ID, GET_DEVICES_BY_SELECTED_GROUP],
      context: {
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN}`,
        },
      },
      onCompleted(data) {
        const onSuccess = async () => {
          await setStage(Stages.AddCollection);
          toast.success("successfully created device");
          setInput({ ...input, id: data.registerDevice.server.deviceId });
          console.log("DEVICE INPUT ID IS", input.id);
        };
        if (data.registerDevice.server !== null) {
          onSuccess();
        } else {
          toast.error(
            "This Device has already been registered or does not exist"
          );
        }
      },
      onError(error) {
        toast.error("error creating device");
      },
    }
  );
  const [
    addToGroup,
    { data: addData, loading: addLoading, error: addError, reset: addReset },
  ] = useMutation(ADD_DEVICE_TO_COLLECTION, {
    refetchQueries: [GET_DEVICES_BY_SELECTED_GROUP],
    context: {
      headers: {
        Authorization: `Bearer ${ACCESS_TOKEN}`,
      },
    },
    onCompleted(data) {},
    onError(error) {
      toast.error("error creating device");
    },
  });

  const [editDeviceTags, mutationObj] = useMutation(EDIT_DEVICE_BY_SERIAL, {
    refetchQueries: [GET_TABLE_DATA_BY_ORG_ID],
    variables: {
      orgId: ORG_ID,
    },
    context: {
      headers: {
        Authorization: `Bearer ${ACCESS_TOKEN}`,
      },
    },
    onCompleted(mutationObj) {
      setStage(Stages.Review);
    },
  });

  useEffect(() => {
    console.log("ORGID ", ORG_ID);
    //reset stage back to register anytime the model is opened.
    setStage(Stages.Register);
  }, [isActive]);
  useEffect(() => {
    console.log("input ", input);
    console.log("allDevices ", allDevices);
  }, [input]);

  return (
    <div className={`${!isActive && "hidden"}`}>
      <div
        className={` absolute z-40 top-0 right-0 opacity-60 h-screen w-screen bg-gradient-to-r from-amniLightBlue to bg-amniGrey`}
      ></div>
      <div className="border-2 w-1/3 border-amniBlue rounded-lg z-50 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white ">
        <div className="flex border-b rounded-t-lg border-amniLightBlue justify-between items-center bg-gradient-to-b from-amniGrey to-white">
          <h2 className="m-4 text-2xl font-extrabold text-amniDarkBlue">
            Create Device
          </h2>
          <div>
            {/* <button
              className={`${stage !== Stages.AddCollection && "hidden"}`}
              onClick={() => {
                stage === Stages.AddCollection && setStage(Stages.Register);
              }}
            >
              <ArrowLeftIcon className="mx-1 w-5 h-5" />
            </button> */}
            <button
              onClick={() => {
                dispatch(toggle());
                console.log("clicked");
              }}
            >
              <XIcon className="w-5 mx-3 h-5" />
            </button>
          </div>
        </div>
        <div className="border-b border-amniLightBlue">
          {stage === Stages.Register && (
            <h3 className="text-xl text-amniBlue m-4">Register Device</h3>
          )}
          {stage === Stages.AddCollection && (
            <h3 className="text-xl text-amniBlue m-4">Add to Collection</h3>
          )}
          {stage === Stages.Review && (
            <div className=" flex m-4 justify-between items-center">
              <h3 className="text-xl text-amniBlue ">Review Device</h3>
              <button
                className="rounded-full bg-amniBlue p-1"
                onClick={() => {
                  setInput({
                    ...input,
                    name: "",
                    serialNum: "",
                  });
                  setStage(Stages.Register);
                }}
              >
                <PlusIcon className="fill-white w-4 h-4 " />
              </button>
            </div>
          )}
          {stage === Stages.Register && (
            <>
              <div className="m-4">
                <label
                  htmlFor="device_name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Device Name
                </label>
                <input
                  type="text"
                  id="device_name"
                  className="bg-white border border-amniLightBlue text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Device 12"
                  value={input.name}
                  onChange={(e) => {
                    setInput({ ...input, name: e.target.value });
                  }}
                  required
                  autoComplete="off"
                />
              </div>
              <div className="m-4 ">
                <label
                  htmlFor="serial_number"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Serial Number
                </label>
                <input
                  type="text"
                  id="serial_number"
                  className="bg-white border border-amniLightBlue text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={(e) => {
                    setInput({ ...input, serialNum: e.target.value });
                  }}
                  placeholder="7 Digit Serial Number"
                  required
                  autoComplete="off"
                  spellCheck={false}
                />
              </div>
            </>
          )}
          {stage === Stages.AddCollection && (
            <div className="px-5 pb-5">
              <div className="flex font-semibold text-amniDarkBlue text-lg items-center">
                {selectedCollection.length !== 0 ? (
                  selectedCollection.map((i: any, index: any) => {
                    return (
                      <>
                        {index !== 0 && <span>&nbsp; &gt; &nbsp;</span>}
                        <button
                          className={`hover:underline ${
                            selectedCollection.length - 1 === index
                              ? "text-2xl"
                              : "text-lg"
                          }`}
                          onClick={() => {
                            setSelectedCollection(
                              selectedCollection.slice(0, index)
                            );
                          }}
                        >
                          {i.name}
                        </button>
                      </>
                    );
                  })
                ) : (
                  <p></p>
                )}
              </div>
              {/* 
              <div>
                <form>
                  <label
                    htmlFor="default-search"
                    className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300"
                  >
                    Search
                  </label>
                  <div className="relative">
                    <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500 dark:text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        ></path>
                      </svg>
                    </div>
                    <input
                      type="search"
                      id="default-search"
                      className="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Search for a Collection..."
                      required
                      autoComplete="off"
                    />
                  </div>
                </form>
              </div>
          */}
              <div className="flex justify-between items-center my-3">
                <h4 className="font-bold text-amniDarkBlue">Collections</h4>
                {/*<div className="flex justify-center items-center">
                  <button className="bg-amniBlue rounded-full">
                    <PlusIcon className="w-4 h-4 fill-white" />
                  </button>
                  <p className="ml-1 text-amniDarkBlue ">New Collection</p>
          </div>*/}
              </div>
              {/*collections table */}
              <GroupMap
                selectedCollection={selectedCollection}
                setSelectedCollection={setSelectedCollection}
              />
            </div>
          )}
          {stage === Stages.Review && (
            <div className="overflow-auto h-96 w-full p-4">
              {allDevices.map((i) => {
                return (
                  <ReviewDeviceCard
                    key={i.serialNum}
                    name={i.name}
                    serial={i.serialNum}
                  />
                );
              })}
            </div>
          )}
        </div>
        <div className="flex justify-center items-center">
          {/* show 'Next' button on every stage but the last */}
          {stage !== Stages.Review && (
            <button
              type="button"
              className="text-white bg-amniDarkBlue hover:bg-amniBlue w-2/3 my-4  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              onClick={() => {
                if (stage === Stages.Register) {
                  registerDevice({
                    variables: {
                      serialNumber: input.serialNum,
                      orgId: ORG_ID,
                    },
                    onCompleted(data) {},
                  });
                }
                if (stage === Stages.AddCollection) {
                  if (selectedCollection.length === 0) {
                    toast.error(
                      "Please add your device to a collection or select unasigned to leave it unasigned"
                    );
                    return;
                  }
                  if (input.id && selectedCollection.at(-1)) {
                    addToGroup({
                      variables: {
                        deviceId: input.id,
                        groupId: selectedCollection.at(-1)?.id,
                      },
                      onCompleted(data) {
                        //add the new device to the collection of devices added
                        setAllDevices([...allDevices, input]);
                        //reset the state of screen 1 addDevice
                        setInput({
                          name: "",
                          serialNum: "",
                          id: "",
                        });
                        //set the screen to stages.review
                        setStage(Stages.Review);
                      },
                      onError(error) {
                        toast.error(`Error ${error.message}`);
                      },
                    });
                  }
                }
              }}
            >
              {stage === Stages.Register &&
                (loading ? (
                  <Spinner />
                ) : error ? (
                  "Try Again"
                ) : (
                  "Register Device"
                ))}
              {stage === Stages.AddCollection && "Next"}
            </button>
          )}
          {stage === Stages.Review && (
            <button
              type="button"
              className="text-white bg-amniDarkBlue hover:bg-amniBlue w-2/3 my-4  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              onClick={() => {
                //call mutation to add all the devices
                //close the model
                dispatch(toggle);
                setStage(Stages.Register);
              }}
            >
              Done
            </button>
          )}
        </div>
        <div className="h-3 w-full bg-amniLightBlue rounded-b-md">
          <div
            className={`bg-amniBlue h-3 rounded-bl-md ${
              stage === Stages.Register && "w-1/3"
            } ${stage === Stages.AddCollection && "w-2/3"} ${
              stage === Stages.Review && "w-3/3 rounded-b-md"
            }`}
          ></div>
        </div>
      </div>
    </div>
  );
}

const GroupMap = ({
  selectedCollection,
  setSelectedCollection,
  ...props
}: {
  selectedCollection: { name: string; id: string }[];
  setSelectedCollection: (arg1: { name: string; id: string }[]) => void;
}) => {
  const { data, loading, error } = useQuery(GET_COLLECTIONS_BY_ORGID, {
    variables: {
      equalTo: localStorage.getItem("orgID"),
    },
    context: {
      headers: {
        Authorization: `Bearer ${ACCESS_TOKEN}`,
      },
    },
    onCompleted(data) {},
  });

  useEffect(() => {
    data &&
      console.log(
        "🚀 ~ file: AddDevice.tsx ~ line 476 ~ onCompleted ~ data",
        data.allGroups.nodes,
        selectedCollection.at(-1)
      );
  }, [data]);

  if (loading) return <Spinner></Spinner>;
  if (error) return <p>{error.message}</p>;
  const recursiveGroupMap = (
    selectedCollection: { name: string; id: string }[]
  ) => {
    return (
      <>
        {data.allGroups.nodes.map((g: any) => {
          const selCol =
            selectedCollection.length === 0
              ? null
              : selectedCollection.at(-1)?.id;
          if (g.parentId === selCol) {
            return (
              <div
                onClick={() => {
                  setSelectedCollection([
                    ...selectedCollection,
                    { id: g.id, name: g.name },
                  ]);
                }}
                className="flex items-center px-4 justify-between gap-2 border-t border-amniLightBlue h-14"
              >
                <div className="flex items-center justify-center gap-2">
                  <CollectionIcon className="w-4 h-4" /> <p>{g.name}</p>
                </div>
                <ChevronRightIcon className="w-4 h-4" />
              </div>
            );
          }
        })}
      </>
    );
  };

  return (
    <div className="h-72 overflow-auto w-full bg-gradient-to-b from-amniGrey rounded-lg">
      <div className="flex items-center px-4 justify-start gap-2  h-14">
        <CollectionIcon className="w-4 h-4" /> Unnasigned
      </div>
      {recursiveGroupMap(selectedCollection)}
    </div>
  );
};

export default AddDevice;
