export const ACCOUNT_SUB_HEADINGS = [
  { name: "User", href: "/app/user/" },
  { name: "Organization", href: "/app/organization/" },
];
export const DEFAULT_TABLE_ROW_COUNT = 10;
export const UNSAVED_CHANGES_ACTIVE = "UNSAVED_CHANGES_ACTIVE";

export const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

export function formatPhoneNumber(phoneNumberString: '') {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
}
