import React from "react";

type Props = {
  children?: React.ReactNode;
  isActive: boolean;
};

function DeviceCardExpansion({ isActive, ...props }: Props) {
  return (
    <div
      className={`flex-grow will-change-auto overflow-auto bg-amniGrey  transition-all ease-in-out duration-500  ${
        isActive ? "max-h-content " : "max-h-0"
      }`}
    >
      {props.children}
    </div>
  );
}

export default DeviceCardExpansion;
