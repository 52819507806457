import React, { useEffect, useState } from "react";
import { GLOBAL_SEARCH } from "../../queries/search";
import useToggle from "../../hooks/useToggle";
import SearchBar from "./SearchBar";
import SearchResults from "./SearchResults";
import TagBadge from "../DevicesScreen/TagBadge";
import SearchItemContainer from "./SearchItemContainer";
import AccountCard from "./AccountCard";
import { useQuery } from "@apollo/client";
import {DeviceCard} from "../Pages/DeviceCard";

type Props = {};

const SearchScreen = (props: Props) => {
  const [toggleView, handleView] = useToggle(false);
  //PROD STATE... UNCOMMENT BEFORE MERGE
  const [input, setInput] = useState();
  const { loading, error, data, refetch } = useQuery(GLOBAL_SEARCH, {
    variables: { input: input },
    //this fetch policy is required to trigger the onCompleted callback below
    //without this fetch policy if you cycle between states that have already been called
    //the onCompleted function will not trigger and the setDevicesInputState will not be triggered
    fetchPolicy: "cache-and-network",
    onCompleted: (res) => {
      console.log("SEARCH RESPONSE", res);
    },
  });

  useEffect(() => {
    console.log(
      "🚀 ~ file: SearchScreen.tsx ~ line 11 ~ SearchScreen ~ input",
      input
    );
  }, [input]);

  const tagData = ["demotag1", "demotag2", "demotag3"];
  const expansionDummyData = {
    id: "15385",
    label: "label2",
  };
  const accountsDummyData = [
    { firstName: "Suresh", lastName: "Yam", role: "OrgAdmin" },
    { firstName: "Ravi", lastName: "Kish", role: "Admin" },
    { firstName: "Ricky", lastName: "Bur", role: "User" },
  ];

  return (
    <>
      <SearchBar handleView={handleView} handleInput={setInput} />
      <SearchResults isVisible={input ? true : false}>
        <SearchItemContainer header="tags" loading={loading} error={error}>
          {input &&
            data?.allTags?.nodes?.map((i: any) => {
              return (
                <a href="/app/devices">
                  <TagBadge key={i} content={i} />;
                </a>
              );
            })}
        </SearchItemContainer>
        <SearchItemContainer header="devices" loading={loading} error={error}>
          {input &&
            data?.allServers?.nodes?.map((i: any) => {
              return (
                <a href="/app/devices">
                  <DeviceCard
                    key={i}
                    handleRightPanelIsActive={undefined}
                    moduleTag={i.tags}
                    activated={i.activated}
                    online={i.online}
                    expansionData={i}
                    // deviceName="Amni-Demo"
                    deviceName={
                      i.label
                        ? i.label
                        : `Amni-${i.serialNumber.substring(
                            i.serialNumber.length - 4
                          )}`
                    }
                    handleEditDeviceId={function (arg0: string): void {
                      throw new Error("Function not implemented.");
                    }}
                    handleSelectedDevices={function (arg0: string): void {
                      throw new Error("Function not implemented.");
                    }}
                  />
                </a>
              );
            })}
        </SearchItemContainer>
        <SearchItemContainer
          header={"accounts"}
          loading={loading}
          error={error}
        >
          {input &&
            data?.allOrgUsers?.nodes?.map((i: any) => {
              return (
                <a href="/app/account">
                  <AccountCard
                    key={i}
                    firstName={i.firstName}
                    lastName={i.lastName}
                    role={i.admin ? "OrgAdmin" : "Editor"}
                  />
                </a>
              );
            })}
        </SearchItemContainer>
      </SearchResults>
    </>
  );
};

export default SearchScreen;
