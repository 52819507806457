import {
  getActiveUnsavedChanges,
  setActiveUnsavedChanges,
} from "./sessionHelpers";

export const alertUnsaved = (
  handleConfirm: () => void,
  handleCancel: () => void
) => {
  const unsavedChangesExist = getActiveUnsavedChanges();
  if (unsavedChangesExist === "true") {
    if (
      window.confirm(
        "There are unsaved changes. Are you sure you want to leave?"
      )
    ) {
      handleConfirm();
      setActiveUnsavedChanges(false);
    } else {
      handleCancel();
    }
  } else {
    handleConfirm();
  }
};
