import React from "react";
import { NavLink } from "react-router-dom";

type Props = {
  name: string;
  title?: string;
  href: string;
  style?: string;
  leftIcon?: any;
  rightIcon?: any;
  menuIsOpen?: boolean;
};

const SideAnchor = ({
  name,
  href,
  style,
  leftIcon,
  rightIcon,
  menuIsOpen,
  title,
}: Props) => {
  return (
    <li title={title}>
      <NavLink
        to={href}
        activeStyle={{
            color: '#3da6ea',
            backgroundColor: 'rgb(243 244 246)'
        }}
        className="flex items-center p-2 text-base font-normal group text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700 "
      >
        <div className="w-6 h-6 flex-shrink-0 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white">
          {leftIcon}
        </div>
        <span
          className={`ml-3 whitespace-nowrap ${
            !menuIsOpen && "lg:hidden lg:absolute"
          }`}
        >
          {name}
        </span>
      </NavLink>
    </li>
  );
};

export default SideAnchor;
