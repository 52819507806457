import React from "react";
import { Toaster } from "react-hot-toast";

type Props = {};

const Toast = (props: Props) => {
  return (
    <Toaster
      toastOptions={{
        success: {
          duration: 5000,
          style: {
            background: "#DEF7EC",
            color: "#046C4E",
          },
        },
        error: {
          duration: 5000,
          style: {
            background: "#FDE8E8",
            color: "#C81E1E",
          },
        },
      }}
    />
  );
};

export default Toast;
