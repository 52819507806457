import React from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import {useAuth0} from "@auth0/auth0-react";

const GuestGuard = ({ children }) => {
  const {isAuthenticated} = useAuth0();

  if (isAuthenticated) {
    return <Redirect to="/app/landingdashboard" />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
