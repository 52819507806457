import React from "react";
import { Link } from "react-router-dom";

type Props = {
  children?: React.ReactNode;
  isActive?: boolean;
  isChecked: boolean;
};

function DeviceCard({ isActive, isChecked, ...props }: Props) {
  return (
    <div
      className={`${isActive ? "row-span-3" : ""} ${
        isChecked && "ring-amniBlue"
      } flex flex-col  relative   ring-2 ring-transparent hover:ring-amniBlue  border-2 drop-shadow-md  transition-all box-shadow-md  rounded-lg bg-white`}
    >
      {props.children}
    </div>
  );
}

export default DeviceCard;
