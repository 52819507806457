import React, { useEffect } from "react";
import "./App.css";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { AuthProvider } from "./contexts/JWTAuthContext";
import routes, { renderRoutes } from "./routes";
import Toast from "./components/Toast/Toast";
import AddCollection from "./components/AddCollection/AddCollection";
import Walkthrough from "./components/Onboarding/Walkthrough";
import AddDevice from "./components/DashScreens/AddDevice/AddDevice";
const history = createBrowserHistory();

function App() {
  return (
    <div className="App bg-gray-50 dark:bg-gray-800">
      <Router history={history}>
        <AuthProvider>
          {renderRoutes(routes)}
          {/* required for package react-hot-toaster */}
          <Toast />
          <AddCollection />
          <AddDevice />
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
