import { XIcon } from "@heroicons/react/solid";
import toast from "react-hot-toast";
import { ACCESS_TOKEN, ORG_ID } from "../../queries/localStorage";
import React, { useEffect, useState } from "react";
import useToggle from "../../hooks/useToggle";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import {
  CREATE_COLLECTION,
  GET_COLLECTIONS_BY_ORGID,
} from "../../queries/collections";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { toggle } from "../../redux/Slices/groupSlice";

type Props = {};

const NameSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(15, "Too Long! 15 character max")
    .required("Required"),
});

const AddCollection = ({}: Props) => {
  const isActive = useAppSelector(
    (state) => state.group.createGrpModelIsActive
  );
  const parentGroup = useAppSelector((state) => state.group.parentGrpId);

  const dispatch = useAppDispatch();

  const [createCollection, mutationObj] = useMutation(CREATE_COLLECTION, {
    refetchQueries: [GET_COLLECTIONS_BY_ORGID],
    context: {
      headers: {
        Authorization: `Bearer ${ACCESS_TOKEN}`,
      },
    },
    onCompleted(mutationObj) {
      toast.success("Success! Collection Created");
      formik.resetForm();
    },
    onError(error) {
      toast.error("Error Creating Collection");
    },
  });

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: NameSchema,
    onSubmit(values, formikHelpers) {
      createCollection({
        variables: {
          organizationId: ORG_ID,
          name: values.name,
          parentId: parentGroup ? parentGroup : null,
        },
      });
    },
  });
  return (
    <div className={`${!isActive && "hidden"} `}>
      <div
        className={`fixed z-40 top-0 right-0 opacity-60 h-screen w-screen bg-gradient-to-r from-amniLightBlue to bg-amniGrey`}
      ></div>
      <div className="fixed border-2 w-2/3 border-amniBlue rounded-lg z-50  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white">
        <div className="flex border-b rounded-t-lg border-amniLightBlue justify-between items-center bg-gradient-to-b from-amniGrey to-white">
          <h2 className="m-4 text-2xl font-extrabold text-amniDarkBlue">
            {parentGroup ? "Create Group" : " Create Collection"}
          </h2>
          <div>
            <button
              onClick={() => {
                dispatch(toggle());
              }}
            >
              <XIcon className="w-5 mx-3 h-5" />
            </button>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="border-b border-amniLightBlue">
            {/* <h3 className="text-xl text-amniBlue m-4">
              {parentGroup ? "Create Group" : " Create Collectio"}
            </h3> */}

            <>
              <div className="m-4">
                <label
                  htmlFor="device_name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="bg-white border border-amniLightBlue text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                  autoComplete="off"
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className="text-red-500">{formik.errors.name}</div>
                ) : null}
              </div>
              <div className=""></div>
            </>
          </div>
          <div className="flex justify-center items-center">
            <button
              type="submit"
              className="text-white bg-amniDarkBlue hover:bg-amniBlue w-2/3 my-4  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              onClick={() => {
                //call mutation to add all the devices
                //close the model
                // handleActive();
              }}
            >
              Create
            </button>
          </div>
        </form>
        <div className="h-3 w-full bg-amniLightBlue rounded-b-md">
          <div
            className={`bg-amniBlue h-3 rounded-b-md w-3/3 rounded-b-lg"
            }`}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default AddCollection;
