import useToggle from "../../hooks/useToggle";
import DeviceCardContainer from "../DevicesScreen/DeviceCardContainer";
import GroupBadge from "../DevicesScreen/GroupBadge";
import TagBadge from "../DevicesScreen/TagBadge";
import Divider from "../DevicesScreen/Divider";
import DeviceCardExpansion from "../DevicesScreen/DeviceCardExpansion";
import StatusBadge from "../DevicesScreen/StatusBadge";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/outline";
import {
  GET_COLLECTION_BY_DEVICEID,
  GET_COLLECTION_NAME_BY_DEVICEID,
} from "../../queries/collections";
import { useQuery } from "@apollo/client";

interface IDeviceProps {
  deviceName: string;
  handleRightPanelIsActive: any;
  moduleTag: string[];
  activated: boolean;
  online: boolean;
  expansionData?: any;
  handleEditDeviceId: (arg0: string) => void;
  handleSelectedDevices: (arg0: string) => void;
}
const dummyData = {
  key: "yolo",
  statusBadge: ["ACTIVATED", "ONLINE"],
  tagBadge: ["AItag1", "AI2", "gatIA3"],
  group: "DemoGroup",
};
export function DeviceCard({
  handleEditDeviceId,
  deviceName,
  moduleTag,
  expansionData,
  activated,
  online,
  handleSelectedDevices,
  ...props
}: IDeviceProps) {
  const [viewPanelActive, handleViewPanelActive] = useToggle(false);
  const [isChecked, setIsChecked] = useToggle(false);

  const {
    loading: getColLoading,
    error: getDevGrpErr,
    data: getDevGrpDta,
    refetch: getDevGrpRefetch,
  } = useQuery(GET_COLLECTION_NAME_BY_DEVICEID, {
    variables: { deviceId: expansionData.deviceId },
    //this fetch policy is required to trigger the onCompleted callback below
    //without this fetch policy if you cycle between states that have already been called
    //the onCompleted function will not trigger and the setDevicesInputState will not be triggered
    fetchPolicy: "cache-and-network",
    onCompleted(data) {
      console.log("🚀 ~ file: DeviceCard.tsx:53 ~ onCompleted ~ data", data);
    },
  });

  // console.log("expansionData..", expansionData);
  const cardData = expansionData?.serverByDeviceId
    ? expansionData?.serverByDeviceId
    : expansionData;
  const rows =
    cardData &&
    Object.keys(cardData).map((i) => {
      const tableHeader = i;
      let tableData = cardData[i];
      //rows to ignore
      if (
        i === "__typename" ||
        i === "activated" ||
        i === "online" ||
        i === "tags" ||
        i === "posId" ||
        i === "licensed" ||
        i === "deviceId" ||
        i === "serverGroupsByGroupId" ||
        i === "name" ||
        i === "childGroups"
      ) {
      } else {
        return (
          <tr key={i} className="text-amniDarkBlue dark:text-gray-400">
            <th className="uppercase text-xs p-2 px-4 font-black text-left align-middle whitespace-nowrap ">
              {tableHeader}
            </th>
            <td className="px-4 text-xs font-medium text-amniDarkBlue align-middle whitespace-nowrap">
              {tableData}
            </td>
          </tr>
        );
      }
    });

  return (
    <DeviceCardContainer isActive={viewPanelActive} isChecked={isChecked}>
      <div className={`relative z-20 p-4 bg-white rounded-lg`}>
        <div className="flex justify-between items-center">
          <h3 className="font-black text-xl text-amniDarkBlue">{deviceName}</h3>
          <button className="flex justify-center items-center">
            {viewPanelActive ? (
              <ChevronUpIcon
                onClick={() => {
                  handleViewPanelActive();
                }}
                className="w-6 h-6 stroke"
              />
            ) : (
              <ChevronDownIcon
                onClick={() => {
                  handleViewPanelActive();
                }}
                className="w-6 h-6 stroke"
              />
            )}
            <input
              id="default-checkbox"
              type="checkbox"
              checked={isChecked}
              className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              onChange={(e) => {
                setIsChecked();
                handleSelectedDevices(cardData?.deviceId);
              }}
            />
          </button>
        </div>
        <div className="relative">
          <div className="h-full w-8 absolute right-0 bottom-0 bg-gradient-to-l from-white  z-30"></div>
          <div className="mt-2 overflow-x-auto flex justify-start items-center gap-2">
            <GroupBadge
              content={
                getDevGrpDta?.serverGroupByDeviceId?.groupByGroupId?.name
                  ? getDevGrpDta?.serverGroupByDeviceId?.groupByGroupId?.name
                  : "Unnasigned"
              }
            />
            {moduleTag?.map((e: any) => {
              return <TagBadge key={e} content={e} />;
            })}
          </div>
        </div>
      </div>
      <Divider />
      <DeviceCardExpansion isActive={viewPanelActive}>
        <div className="flex pl-2 items-center justify-between ">
          <table className="items-center border-collapse">
            <tbody className="  dark:divide-gray-700">
              {/* table rows function goes here */}
              {rows}
            </tbody>
          </table>
        </div>
        <button
          className="bg-amniDarkBlue rounded-lg h-10 my-3 items-center w-11/12 flex justify-center mx-auto text-white"
          onClick={() => {
            handleEditDeviceId(cardData?.deviceId);
            props.handleRightPanelIsActive(true);
          }}
        >
          Edit Device
        </button>
      </DeviceCardExpansion>
      <div className="relative">
        <div className="h-full w-8 rounded-lg absolute right-0 bottom-0 bg-gradient-to-l from-white  z-30"></div>
        <div className="overflow-x-auto my-4 ml-4 flex justify-start items-center gap-2">
          <StatusBadge isActive={online} content={"online"} />
          <StatusBadge isActive={activated} content={"activated"} />
        </div>
      </div>
    </DeviceCardContainer>
  );
}
