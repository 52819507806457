import { Footer } from "flowbite-react";
import React from "react";

function BottomFooter() {
  return (
    <Footer container={true} >
      <Footer.LinkGroup>
        <Footer.Link
          target="_blank"
          href="https://app.termly.io/document/terms-of-use-for-website/cb2ebc94-d184-4bd3-baea-b34befe7a600"
        >
          Terms & Conditions
        </Footer.Link>
        <Footer.Link
          target="_blank"
          href="https://app.termly.io/document/privacy-policy/bdb1000d-347f-4e15-8435-0e579b030ce4"
        >
          Privacy Policy
        </Footer.Link>
      </Footer.LinkGroup>
    </Footer>
  );
}

export default BottomFooter;
