import { gql } from "@apollo/client";

export const GLOBAL_SEARCH = gql`
  query GlobalSearch($input: String!) {
    allServers(
      filter: {
        or: [
          { name: { includesInsensitive: $input } }
          { serialNumber: { includesInsensitive: $input } }
        ]
      }
    ) {
      nodes {
        deviceId
        name
        serialNumber
        activated
        online
        deviceMode
        attributes
        hostIp
        siteId
        mqPort
        mqSubTopic
        mqTopic
        sseUrl
        tags
      }
    }
    allOrgUsers(
      filter: {
        or: [
          { firstName: { includesInsensitive: $input } }
          { lastName: { includesInsensitive: $input } }
        ]
      }
    ) {
      nodes {
        email
        firstName
        lastName
        jobRole
        admin
      }
    }
    allTags(filter: { includesInsensitive: $input }) {
      nodes
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query UserById($userId: UUID!) {
    orgUserByUserId(userId: $userId) {
      firstName
      lastName
    }
  }
`;
