import React, { useEffect } from "react";
import BottomFooter from "../Footer/Bottom-footer";
import NavBar from "../NavBar/NavBar";
import Sidepanel from "../DashScreens/Sidepanel/Sidepanel";
import useToggle from "../../hooks/useToggle";
import SearchScreen from "../SearchBar/SearchScreen";
import { useAppSelector } from "../../redux/hooks";

type Props = {
  children?: React.ReactNode;
  footer?: React.ReactNode;
  menuOpen?: boolean;
};

const MainContainer = ({ children }: Props) => {
  const [menuIsOpen, setMenuIsOpen] = useToggle(true);
  const [popupIsOpen, setPopupIsOpen] = useToggle(false);

  //import popups so we can check if they are open and set the overflow to hidden
  const onboardingPopupIsActive = useAppSelector(
    (state) => state.onboarding.modelIsActive
  );
  const addDeviceModalActive = useAppSelector(
    (state) => state.devices.createDevModelIsActive
  );
  const addCollectionModalActive = useAppSelector(
    (state) => state.group.createGrpModelIsActive
  );

  useEffect(() => {
    //set overflow-hidden & h-screen if any of the popups are open
    if (
      onboardingPopupIsActive ||
      addDeviceModalActive ||
      addCollectionModalActive
    ) {
      setPopupIsOpen(true);
    } else {
      setPopupIsOpen(false);
    }
  }, [onboardingPopupIsActive, addDeviceModalActive, addCollectionModalActive]);

  return (
    <>
      <div
        className={`App bg-gray-50 dark:bg-gray-800 ${
          popupIsOpen && "h-screen overflow-hidden "
        }`}
      >
        <NavBar panelToggle={setMenuIsOpen} panelIsOpen={menuIsOpen} />
        <Sidepanel menuIsOpen={menuIsOpen} />
        <div className="flex pt-16 overflow-hidden bg-gray-50 dark:bg-gray-900">
          <div
            id="main-content"
            style={{ background: "#EBF0F3", marginTop: "0.7rem" }}
            className={`relative w-full h-full overflow-y-auto bg-gray-50  dark:bg-gray-900 main-container  ${
              menuIsOpen ? "ml-64" : "lg:ml-16"
            }`}
          >
            <SearchScreen />
            <main>{children}</main>
          </div>
        </div>
        <div className={menuIsOpen ? "footer ml-64" : " footer lg:ml-16"}>
          <BottomFooter />
        </div>
      </div>
    </>
  );
};

export default MainContainer;
