import React from "react";

type Props = {
  isVisible: boolean;
  children: React.ReactNode;
};

function SearchResults({ isVisible, ...props }: Props) {
  return (
    <div
      className={`${
        !isVisible && "-translate-y-full"
      }  transition-all overscroll-auto pt-16 text-slate-500 bg-amniGrey absolute h-full w-full z-10 top-0 left-0`}
    >
      {props.children}
    </div>
  );
}

export default SearchResults;
