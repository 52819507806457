import { gql } from "@apollo/client";
import { DEFAULT_TABLE_ROW_COUNT } from "../constants";

export const PIE_DEVICE_MODE_QUERY = gql`
  query PieQuery {
    allServers {
      nodes {
        activated
        deviceMode
        online
        deviceId
      }
    }
  }
`;

export const RECENT_CHANGES = gql`
  query recentChanges {
    allServers(
      first: 10
      orderBy: LAST_MODIFIED_AT_DESC
      filter: { lastModifiedBy: { isNull: false } }
    ) {
      nodes {
        serialNumber
        lastModifiedAt
        lastModifiedBy
      }
    }
  }
`;

export const ALL_GROUPS_HEADER = gql`
  query MyQuery16 {
    allGroups(filter: { type: { equalTo: COLLECTION } }) {
      nodes {
        name
        id
        organizationId
      }
    }
  }
`;

export const DEVICE_TEMPERATURE = gql`
  query deviceTemperature {
    allServers(filter: { deviceTemperature: { isNull: false } }, first: 5) {
      nodes {
        serialNumber
        deviceTemperature
      }
    }
  }
`;

export const GET_TAGS_BY_ORG_ID = gql`
  query AllDevicesQuery(
    $equalTo: UUID!
    $first: Int = ${DEFAULT_TABLE_ROW_COUNT}
    $offset: Int = 0
    $orderBy: [ServersOrderBy!] = NATURAL
  ) {
    allServers(first: $first, offset: $offset, orderBy: $orderBy,filter: {organizationId: {equalTo: $equalTo}} ) {
      totalCount
        nodes {
          tags
        }
    }
  }
`;

export const GET_TABLE_DATA_BY_ORG_ID = gql`
  query AllDevicesQuery(
    $equalTo: UUID!
    # $overlaps: [String]!
    $first: Int = ${DEFAULT_TABLE_ROW_COUNT}
    $offset: Int = 0
    $orderBy: [ServersOrderBy!] = NATURAL
  ) {
    # allServers(first: $first, offset: $offset, orderBy: $orderBy,filter: {organizationId: {equalTo: $equalTo}, tags: {overlaps: $overlaps}} ) {
    allServers(first: $first, offset: $offset, orderBy: $orderBy,filter: {organizationId: {equalTo: $equalTo} } ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
        nodes {
          deviceId
          serialNumber
          hostIp
          name
          activated
          online
          deviceMode
          tags
          attributes
          licensed
          posId
          siteId
          mqPort
          mqSubTopic
          # mqTcpPort
          mqTopic
          # rtspPort
          sseUrl
          make
          model
        }
    }
  }
`;
export const GET_TABLE_DATA = gql`
  query AllDevicesQuery(
    $first: Int = ${DEFAULT_TABLE_ROW_COUNT}
    $offset: Int = 0
    $orderBy: [ServersOrderBy!] = NATURAL
  ) {
    allServers(first: $first, offset: $offset, orderBy: $orderBy) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
        nodes {
          deviceId
          serialNumber
          name
          activated
          online
          deviceMode
          tags
          attributes
          hostIp
          licensed
          posId
          siteId
          mqPort
          mqSubTopic
          # mqTcpPort
          mqTopic
          # rtspPort
          sseUrl
        }
    }
  }
`;

export const EDIT_DEVICE_BY_ID = gql`
  mutation EditDeviceById(
    $activated: Boolean
    $attributes: JSON
    $deviceId: UUID!
    $name: String
    $posId: String
    $siteId: String
    $tags: [String]
    $deviceMode: DeviceModes
    $lastModifiedBy: String
  ) {
    updateServerByDeviceId(
      input: {
        serverPatch: {
          activated: $activated
          attributes: $attributes
          deviceMode: $deviceMode
          name: $name
          posId: $posId
          siteId: $siteId
          tags: $tags
          lastModifiedBy: $lastModifiedBy
        }
        deviceId: $deviceId
      }
    ) {
      clientMutationId
    }
  }
`;

export const EDIT_MANY_TAGS_BY_ID = gql`
  mutation MyMutation($mnServerPatch: [ServerPatch!]) {
    mnUpdateServerByDeviceId(input: { mnServerPatch: $mnServerPatch }) {
      clientMutationId
    }
  }
`;

export const EDIT_DEVICE_BY_SERIAL = gql`
  mutation EditBySerial($tags: [String] = "", $serialNumber: String = "") {
    updateServerBySerialNumber(
      input: { serverPatch: { tags: $tags }, serialNumber: $serialNumber }
    ) {
      clientMutationId
    }
  }
`;

export const REGISTER_DEVICE = gql`
  mutation RegisterDevice($orgId: UUID!, $serialNumber: String = "") {
    registerDevice(input: { serialNumber: $serialNumber, orgId: $orgId }) {
      clientMutationId
      server {
        activated
        deviceId
      }
    }
  }
`;

export const GET_DEVICE_BY_ID = gql`
  query getDeviceById($deviceId: UUID!) {
    serverByDeviceId(deviceId: $deviceId) {
      organizationId
      name
      attributes
      activated
      hostIp
      sseUrl
      sseToken
      mqPort
      mqTopic
      mqSubTopic
      posId
      faceDetection
      deviceMode
      cameraAlphaType
      cameraAlphaStatus
      cameraBetaType
      cameraBetaStatus
      createdAt
      siteId
      groupId
      subGroupId
      licensed
      mqTcpPort
      rtspPort
      clientKey
      clientCrt
      mqHealth
      lastModifiedAt
      lastModifiedBy
      detectionTag
      deviceTemperature
      online
      serialNumber
      tags
      userId
    }
  }
`;

export const AllGroupsAllServers = gql`
  query allGroups($organizationId: UUID) {
    allServers(condition: { organizationId: $organizationId }) {
      nodes {
        deviceId
        activated
        make
        model
        createdAt
        firmwareVersion
        detectionTag
        lastModifiedBy
        lastModifiedAt
        deviceTemperature
        deviceMode
        attributes
        name
        online
        serialNumber
        tags
        hostIp
        sseUrl
      }
    }
  }
`;


export const RESET_DEVICE_1 = gql`
  mutation ResetDevice1($deviceId: UUID!, $organizationId: UUID) {
    updateServerByDeviceId(
      input: {
        serverPatch: { organizationId: $organizationId }
        deviceId: $deviceId
      }
    ) {
      clientMutationId
    }
  }
`;

export const RESET_DEVICE_2 = gql`
  mutation ResetDevice2($deviceId: UUID!) {
    createServerAction(
      input: { serverAction: { deviceId: $deviceId, type: RESET_DEVICE } }
    ) {
      clientMutationId
    }
  }
`;


export const GET_DEVICES_BY_SELECTED_GROUP = gql`
  query TableQuery($groupId: UUID, $organizationId: UUID!) {
    allGroups(
      filter: {
        organizationId: { equalTo: $organizationId }
        id: { equalTo: $groupId }
      }
    ) {
      nodes {
        id
        name
        serverGroupsByGroupId {
          nodes {
            serverByDeviceId {
              deviceId
              activated
              make
              model
              createdAt
              firmwareVersion
              detectionTag
              lastModifiedBy
              lastModifiedAt
              deviceTemperature
              deviceMode
              attributes
              name
              online
              serialNumber
              tags
            }
          }
        }
        childGroups {
          nodes {
            id
            name
            serverGroupsByGroupId {
              nodes {
                serverByDeviceId {
                  deviceId
                  activated
                  make
                  model
                  createdAt
                  firmwareVersion
                  detectionTag
                  lastModifiedBy
                  lastModifiedAt
                  deviceTemperature
                  deviceMode
                  attributes
                  name
                  online
                  serialNumber
                  tags
                }
              }
            }
            childGroups {
              nodes {
                id
                name
                serverGroupsByGroupId {
                  nodes {
                    serverByDeviceId {
                      deviceId
                      activated
                      make
                      model
                      createdAt
                      firmwareVersion
                      detectionTag
                      lastModifiedBy
                      lastModifiedAt
                      deviceTemperature
                      deviceMode
                      attributes
                      name
                      online
                      serialNumber
                      tags
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allServers(filter: { organizationId: { equalTo: $organizationId } }) {
      nodes {
        deviceId
        activated
        make
        model
        createdAt
        firmwareVersion
        detectionTag
        lastModifiedBy
        lastModifiedAt
        deviceTemperature
        deviceMode
        attributes
        name
        online
        serialNumber
        tags
      }
    }
  }
`;
