import React from "react";
import SideAnchor from "./SideAnchor";
import { alertUnsaved } from "../../../helpers/alerts";
import {
  ChartPieIcon,
  DeviceMobileIcon,
  DocumentIcon,
  UserIcon,
} from "@heroicons/react/outline";

type Props = {
  menuIsOpen: boolean;
};

function Sidepanel({ menuIsOpen }: Props) {
  return (
    <>
      <aside
        id="sidebar"
        className={`${
          menuIsOpen ? "flex w-64" : "hidden w-16"
        } fixed top-0 left-0 z-20 flex-col flex-shrink-0 pt-16  h-full duration-75 lg:flex transition-width`}
        style={{ borderRight: "2px solid #C5E4F9" }}
        aria-label="Sidebar"
      >
        <div
          className="flex relative flex-col flex-1 pt-0 min-h-0 bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700"
          style={{ background: "#EBF0F3" }}
        >
          <div
            className="flex overflow-y-auto flex-col flex-1 pt-5 pb-4"
            style={{ background: "#EBF0F3" }}
          >
            <div
              onClick={(e) => {
                alertUnsaved(
                  () => {},
                  () => {
                    e.preventDefault();
                  }
                );
              }}
              className="flex-1 px-3 space-y-1 bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700"
              style={{
                background: "#EBF0F3",
              }}
            >
              <ul className="pb-2 space-y-2">
                <SideAnchor
                  name="Devices"
                  title="Devices"
                  href="/app/devices"
                  menuIsOpen={menuIsOpen}
                  leftIcon={<DeviceMobileIcon />}
                />
                <SideAnchor
                  name="Account"
                  title="Account"
                  href="/app/account"
                  menuIsOpen={menuIsOpen}
                  leftIcon={<UserIcon />}
                />
              </ul>
              <div className="pt-2 space-y-2">
                <ul>
                  <SideAnchor
                    name="Docs"
                    title="Docs"
                    href="https://edge.ai.amniscient.com/docs/"
                    leftIcon={<DocumentIcon />}
                    menuIsOpen={menuIsOpen}
                  />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </aside>
      <div
        className={`${
          !menuIsOpen ? "hidden" : "lg:hidden"
        } fixed inset-0 z-10 bg-gray-900/50 dark:bg-gray-900/90`}
      />
    </>
  );
}

export default Sidepanel;
