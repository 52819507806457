import React from 'react'

type Props = {}

function Divider({}: Props) {
  return (
    <div className='h-0 border border-blue-300'></div>
  )
}

export default Divider