import React from "react";

type Props = {
  content?: string;
};

const data = "HPISD";

function GroupBadge({ content }: Props) {
  return (
      <div className=" flex justify-center items-center  h-6 px-3 py-0.5 rounded bg-amniDarkBlue">
          <p className="whitespace-pre-wrap flex-grow-0 flex-shrink-0 text-[11px] leading-[1.5] text-center text-white">
              {content ? content : data}
          </p>
    </div>
  );
}

export default GroupBadge;
