import React from "react";

type Props = {
  content?: string;
  isActive?: boolean;
};

const data = "STATUS";

function StatusBadge({ content, isActive }: Props) {
  return (
    <div className="flex px-4 min-w-20 h-6 justify-center items-center rounded bg-amniGrey">
      <div
        className={`h-2.5 w-2.5 rounded-full ${
          isActive ? "bg-green-400" : "bg-white"
        } mr-2 `}
      />
      <p className="uppercase font- text-xs  text-amniBlue">
        {content ? content : data}
      </p>
    </div>
  );
}

export default StatusBadge;
