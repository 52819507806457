import { gql } from "@apollo/client";
import { ORG_ID } from "./localStorage";

//parentId = null to create collection parentId = UUID for group
export const CREATE_COLLECTION = gql`
  mutation CreateGroup(
    $name: String!
    $organizationId: UUID!
    $parentId: UUID
  ) {
    createGroup(
      input: {
        group: {
          name: $name
          organizationId: $organizationId
          parentId: $parentId
        }
      }
    ) {
      clientMutationId
    }
  }
`;

export const GET_COLLECTIONS_BY_ORGID = gql`
  query GetCollectionByOrgId($equalTo: UUID) {
    allGroups(
      filter: {
        organizationId: { equalTo: $equalTo }
        type: { equalTo: COLLECTION }
      }
    ) {
      nodes {
        name
        id
        parentId
        serverGroupsByGroupId {
          nodes {
            deviceId
          }
        }
        childGroups {
          nodes {
            name
            id
            parentId
            serverGroupsByGroupId {
              nodes {
                deviceId
              }
            }
            childGroups {
              nodes {
                id
                name
                parentId
                serverGroupsByGroupId {
                  nodes {
                    deviceId
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GROUP_BY_PARENTID = gql`
  query GroupByParentId($equalTo: UUID) {
    allGroups(filter: { parentId: { equalTo: $equalTo } }) {
      nodes {
        id
        parentId
        name
        serverGroupsByGroupId {
          nodes {
            deviceId
          }
        }
      }
    }
  }
`;

export const ADD_DEVICE_TO_COLLECTION = gql`
  mutation addDeviceToGroup($deviceId: UUID!, $groupId: UUID!) {
    createServerGroup(
      input: { serverGroup: { deviceId: $deviceId, groupId: $groupId } }
    ) {
      clientMutationId
    }
  }
`;

export const EDIT_GROUP_BY_ID = gql`
  mutation EditGroupById($id: UUID!, $name: String) {
    updateGroupById(input: { id: $id, groupPatch: { name: $name } }) {
      clientMutationId
      group {
        name
        id
      }
    }
  }
`;

export const DELETE_GROUP_BY_ID = gql`
  mutation DeleteGroup($id: UUID!) {
    deleteGroupById(input: { id: $id }) {
      deletedGroupId
    }
  }
`;

export const REMOVE_DEVICE_FROM_COLLECTION = gql`
  mutation removeDeviceFromGroup($deviceId: UUID!) {
    deleteServerGroupByDeviceId(input: { deviceId: $deviceId }) {
      clientMutationId
      deletedServerGroupId
    }
  }
`;

export const GET_COLLECTION_BY_DEVICEID = gql`
  query CollectionByDeviceId($deviceId: UUID!) {
    serverGroupByDeviceId(deviceId: $deviceId) {
      groupId
    }
  }
`;

export const GET_COLLECTION_NAME_BY_DEVICEID = gql`
  query CollectionByDeviceId($deviceId: UUID!) {
    serverGroupByDeviceId(deviceId: $deviceId) {
      groupId
      groupByGroupId {
        name
      }
    }
  }
`;

export const GROUP_TABS = gql`
  query getCollectionsByOrgId($equalTo: UUID!) {
    allGroups(
      filter: {
        organizationId: { equalTo: $equalTo }
        type: { equalTo: COLLECTION }
      }
    ) {
      nodes {
        id
        name
        type
      }
    }
  }
`;

export const GET_GROUPS_BY_PARENTID = gql`
  query GroupsByParentId($parentId: UUID!) {
    allGroups(condition: { parentId: $parentId }) {
      nodes {
        id
        name
        childGroups {
          nodes {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_GROUP_BY_ID = gql`
  query GroupById($id: UUID!) {
    groupById(id: $id) {
      name
    }
  }
`;
