import React from 'react';
import {Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useAuth0} from "@auth0/auth0-react";

const AuthGuard = ({children}) => {
  const {isAuthenticated} = useAuth0();

  if (!isAuthenticated) {
    return <Redirect to="/create-organization"/>;
  }

  return (
    <>
      {children}
    </>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
