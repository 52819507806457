import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export enum OnboardStage {
  Tabs = 0,
  Manage = 1,
  Subgroups = 2,
  Expand = 3,
  MultiSelect = 4,
}

// Define a type for the slice state
export interface OnboardState {
  modelIsActive: boolean;
  stage: OnboardStage;
  playedThrough: boolean;
}

// Define the initial state using that type
const initialState: OnboardState = {
  modelIsActive: false,
  stage: OnboardStage.Tabs,
  playedThrough: false,
};

export const onboardSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    toggle: (state, action: PayloadAction<boolean>) => {
      action
        ? (state.modelIsActive = action.payload)
        : (state.modelIsActive = !state.modelIsActive);
      localStorage.setItem("onboardPlayedThrough", "true");
    },
    next: (state) => {
      if (state.stage !== OnboardStage.MultiSelect) {
        //increment stage
        state.stage = state.stage + 1;
        console.log("state.stage set to", state.stage);
      } else {
        //reset to initial state and close modal
        state.stage = OnboardStage.Tabs;
        state.modelIsActive = false;
        console.log("state.onboardModelIsActive set to", state.modelIsActive);
      }
    },
    back: (state) => {
      //decrement stage
      state.stage = state.stage - 1;
      console.log("state.stage set to", state.stage);
    },
    togglePlayedThrough: (state) => {
      state.playedThrough = !state.playedThrough;
      console.log("state.playedThrough toggled to", state.playedThrough);
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggle, back, next, togglePlayedThrough } = onboardSlice.actions;

export default onboardSlice.reducer;
