import React, { useState } from "react";

function useToggle(initialValue: boolean) {
  const [isActive, setIsActive] = useState(initialValue);
  const mediaQuery = window.matchMedia("(min-width: 390px)");
  const [query, setQuery] = React.useState<any>(mediaQuery);
  const [expanded, setExpanded] = React.useState(query.matches);

  React.useEffect(() => {
    mediaQuery.addListener(setQuery);
    return () => mediaQuery.removeListener(setQuery);
  }, [mediaQuery]);

  React.useEffect(() => {
    setExpanded(query.matches);
  }, [query]);

  const handleIsActive = (newState?: boolean) => {
    if (newState === true || newState === false) {
      setIsActive(newState);
    } else setIsActive(!isActive);
  };

  const hookReturn: [boolean, (arg0?:boolean) => void] = [
    expanded && isActive,
    handleIsActive,
  ];

  return hookReturn;
}

export default useToggle;
