import { PayloadAction, createSlice } from "@reduxjs/toolkit";

// Define a type for the slice state
interface GrpState {
  createGrpModelIsActive: boolean;
  parentGrpId: string;
}

// Define the initial state using that type
const initialState: GrpState = {
  createGrpModelIsActive: false,
  parentGrpId: "",
};

export const groupSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    toggle: (state) => {
      state.createGrpModelIsActive = !state.createGrpModelIsActive;
      console.log(
        "state.createGrpModelIsActive toggled to",
        state.createGrpModelIsActive
      );
    },
    setParentId: (state, action: PayloadAction<string>) => {
      state.parentGrpId = action.payload;
      console.log("state.parentGrpId set to", state.parentGrpId);
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggle, setParentId } = groupSlice.actions;

export default groupSlice.reducer;
