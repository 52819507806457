import React, { useState } from "react";

type Props = {
  handleView: () => void;
  handleInput: (event: any) => void;
};

function SearchBar({ handleView, handleInput, ...props }: Props) {

  return (
    <>
      <div className="z-20 sticky  top-0">
        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <button
            onClick={() => {
              handleInput("");
            }}
          >
            <svg
              aria-hidden="true"
              className="stroke-amniBlue w-5 h-5 text-gray-500 dark:text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </button>
        </div>
        <input
          type="search"
          id="default-search"
          className={`text-amniDarkBlue placeholder:text-amniBlue block p-4 pl-10 w-full text-sm border-b border-t-transparent border-x-transparent border-b-amniLightBlue focus:ring-amniBlue focus:border-amniBlue dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:bg-gradient-to-l focus:from-amniGrey focus:to-amniLightBlue bg-amniGrey`}
          placeholder="Search for devices, groups, tags, people..."
          autoComplete="off"
          onChange={(e) => {
            handleInput(e.target.value);
          }}
          required
        />
      </div>
    </>
  );
}

export default SearchBar;
