import { XIcon } from "@heroicons/react/solid";
import React from "react";

type Props = {
  content?: string;
  editIsActive?: boolean;
  onXClick?: () => void;
};

const data = "AiTag";

function TagBadge({ content, editIsActive, onXClick }: Props) {
  return (
      <div className=" flex justify-center items-center  h-6 px-3 py-0.5 rounded bg-amniLightBlue">
        <p className="whitespace-pre-wrap flex-grow-0 flex-shrink-0 text-[11px] leading-[1.5] text-center text-amniDarkBlue">
          {content ? content : data}
        </p>
        {editIsActive && (
          <button onClick={onXClick && onXClick}>
            <XIcon className="ml-1 w-3 h-3 flex" />
          </button>
        )}
      </div>
  );
}

export default TagBadge;
