import React, { useEffect, useState } from "react";
import gadgetLogo from "../../assets/images/AmniGadget.png";
import amniscientLogo from "../../assets/images/Amniscient@2x.png";
import { MenuAlt1Icon, XIcon } from "@heroicons/react/solid";
import { useHistory, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { Dropdown, Avatar } from "flowbite-react";
import { productApi } from "../../api/queries";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { toggle } from "../../redux/Slices/onboardSlice";
import { useAuth0 } from "@auth0/auth0-react";
import {setSession} from "../../contexts/JWTAuthContext";

type Props = {
  panelToggle: () => void;
  panelIsOpen: boolean;
};

export interface OrgUserByUserID {
  phoneNumber: string;
  firstName: string;
  lastName: string;
  country: string;
  jobRole: string;
  email: null;
  organizationByOrganizationId: OrganizationByOrganizationID;
}

export interface OrganizationByOrganizationID {
  name: string;
}

function NavBar({ panelToggle, panelIsOpen }: Props) {
  const history = useHistory();
  const { logout } = useAuth0();
  const email = window.localStorage.getItem("email");

  const [currentUserDetails, setCurrentUserDetails] = useState<
    OrgUserByUserID | undefined
  >();
  const dispatch = useAppDispatch();

  const isActive = useAppSelector((state) => state.onboarding.modelIsActive);

  const location = useLocation();

  useEffect(() => {
/*    async function fetchData() {
      const userId = window.localStorage.getItem("userId");
      console.log("userId..", userId);
      try {
        const res = await productApi.getUserInformation(userId);
        console.log("res..", res);
        setCurrentUserDetails(res?.orgUserByUserId);
      } catch (error) {
        console.log("error..", error);
      }
    }
    fetchData();*/
  }, []);
  const handleLogout = async () => {
    try {
      await logout();
      setSession(null)
      history.push("/");
    } catch (err) {
      console.error(err);
    }
  };
  const handleHelp = () => {
    dispatch(toggle(!isActive));
  };
  const handleSetting = () => {
    history.push("/app/account/");
  };

  return (
    <nav className="fixed z-30 w-full   dark:bg-gray-800 dark:border-gray-700 bg-gradient-to-r from-amniGrey to-amniLightBlue shadow-md">
      <div className="py-3 px-3 lg:px-5 lg:pl-3">
        <div className="flex justify-between items-center">
          <div className="flex justify-start items-center">
            {/* menu button desktop */}
            <button
              id="toggleSidebar"
              onClick={panelToggle}
              aria-expanded="true"
              aria-controls="sidebar"
              className="hidden p-2 mr-3 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700"
            >
              <MenuAlt1Icon className="w-6 h-6" />
            </button>
            {/* menu button mobile */}
            <button
              id="toggleSidebarMobile"
              onClick={panelToggle}
              aria-expanded="true"
              aria-controls="sidebar"
              className="p-2 mr-2 text-gray-600 rounded cursor-pointer lg:hidden hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 dark:focus:bg-gray-700 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              {panelIsOpen ? (
                <XIcon className="w-6 h-6" />
              ) : (
                <MenuAlt1Icon className="w-6 h-6" />
              )}
            </button>
          </div>
          <a href="/" className="flex">
            <img
              src={
                location.pathname.includes("landingdashboard")
                  ? amniscientLogo
                  : gadgetLogo
              }
              className="h-12"
              alt="Amniscient Logo"
            />
          </a>

          <div className="flex items-center">
            <div
              id="tooltip-toggle"
              role="tooltip"
              className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip"
            >
              Toggle dark mode
              <div className="tooltip-arrow" data-popper-arrow></div>
            </div>
            {/* <!-- Profile --> */}
            <div className="flex items-center ml-3">
              <Dropdown
                arrowIcon={false}
                inline={true}
                label={
                  <div className="inline-flex overflow-hidden relative justify-center items-center w-10 h-10 bg-gray-100 rounded-full dark:bg-gray-600">
                    <span className="font-medium text-gray-600 dark:text-gray-300">
                      {currentUserDetails?.firstName?.charAt(0).toUpperCase()}
                      {currentUserDetails?.lastName?.charAt(0)?.toUpperCase()}
                    </span>
                  </div>
                }
              >
                <Dropdown.Header>
                  <span className="block text-sm">
                    {currentUserDetails?.firstName}{" "}
                    {currentUserDetails?.lastName}
                  </span>
                  <span className="block truncate text-sm font-medium">
                    {email}
                  </span>
                </Dropdown.Header>
                <Dropdown.Item onClick={handleSetting}>Settings</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={handleHelp}>Help</Dropdown.Item>
                <Dropdown.Item onClick={handleLogout}>Sign out</Dropdown.Item>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
