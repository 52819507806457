import React, { createContext, useEffect, useReducer } from "react";
import jwtDecode from "jwt-decode";
import { gql } from "@apollo/client";
import request from "graphql-request";
import SplashScreen from "../components/helper-components/SplashScreen";
import axios from "../utils/axios";
import { useHistory } from "react-router-dom";
import { decode } from "../utils/jwt";
import {useAuth0} from "@auth0/auth0-react";

const initialAuthState = {
  isAuthenticated: true,
  isInitialised: false,
  user: null,
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

export const setSession = (accessToken, email) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("email", email);
    const payload = decode(accessToken);
    const userId = payload?.user_id;
    const userRole = payload?.role;
    localStorage.setItem("userId", userId);
    localStorage.setItem("userRole", userRole);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("email");
    localStorage.removeItem("orgID");
    localStorage.removeItem("userId");
    localStorage.removeItem("userRole");
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INITIALISE": {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case "LOGIN": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case "REGISTER": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: "JWT",
  login: () => Promise.resolve(),
  getOrgId: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const history = useHistory();
  const {
    getAccessTokenSilently
  } = useAuth0();
  const { user, isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();
  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = "dev-1pfvkvu7f04hyg80.us.auth0.com";
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: "postgraphile",
            grant_type: "password",
          },
        });

        setSession(accessToken,user?.email);
        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user?.sub}`;
        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const { user_metadata } = await metadataResponse.json();
      } catch (e) {
        console.log(e.message);
      }
    };
    getUserMetadata();
  }, [getAccessTokenSilently, user?.sub]);

  const endpoint = process.env.REACT_APP_END_POINT;

  const login = async (
    email,
    password,
    setStatus,
    setSubmitting,
    setErrors
  ) => {
/*    localStorage.setItem("email", email);
    localStorage.removeItem("authStatus");
    await fetch("https://dev-1pfvkvu7f04hyg80.us.auth0.com/oauth/token", {
      method: "POST",
      body: JSON.stringify({
        client_id: "6ZSM8LClnM2oeMD0AO4SIyNCsCjkgNOv",
        client_secret:
          "fbzHW8pMG63jHTVUL6qwFFO54mhwjR-KmHxtCN6QGFU7W6NYtuxFK3VrmaD5B9Jc",
        audience: "postgraphile",
        grant_type: "password",
        username: email,
        password: password,
        /!*      username: "ritesh.nallola@amniscient.com" ,
              password: "Amniscient1!",*!/
      }),
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => {
        console.log("res.json()", res);
        return res.json();
      })
      .then((response) => {
        console.log("response", response);
        const headerToken = response?.access_token;
        const mfaToken = response?.mfa_token;
        const email = window.localStorage.getItem("email");
        if (mfaToken) {
          //  getMFA(mfaToken);
          postMFA(email, mfaToken);
        }
        if (headerToken) {
          setSession(headerToken, email);
          window.localStorage.setItem("authStatus", "NONE");
          history.push("/app/dashboard/");
          console.log("response", headerToken);
          getOrgId(email, headerToken, setStatus, setSubmitting, setErrors);
        }
      })
      .catch((error) => {
        console.log(error);
        setStatus({ success: false });
        setErrors({
          submit:
            error === "Something went wrong"
              ? "We're having trouble connecting. Please try agian later"
              : error.message,
        });
      });*/
  };

  //Fetch - OrgId
  const getOrgId = async (
    email,
    headerToken,
    setStatus,
    setSubmitting,
    setErrors
  ) => {
    const fetchOrgIdQuery = gql`
      query getOrganizationId($email: String!) {
        orgUserByEmail(email: $email) {
          organizationId
        }
      }
    `;
    const variable = { email };
    const requestHeaders = {
      authorization: `Bearer ${headerToken}`,
    };
    request(endpoint, fetchOrgIdQuery, variable, requestHeaders).then(
      (response) => {
        console.log("getOrgId", response);
        if (response?.orgUserByEmail?.organizationId) {
          localStorage.setItem(
            "orgID",
            response?.orgUserByEmail?.organizationId
          );
        }
        dispatch({
          type: "LOGIN",
          payload: {
            user: {
              email: email,
              name: email,
              orgID: response?.orgUserByEmail?.organizationId,
            },
          },
        });

        //  setSubmitting(false);
        setStatus({ success: true });
      },
      (error) => {
        console.log("getOrgId error", error);
        setStatus({ success: false });
        setErrors({
          submit:
            error === "Something went wrong"
              ? "We're having trouble connecting. Please try agian later"
              : error.message,
        });
        //    setSubmitting(false);
      }
    );
  };

  //Logout
  const logout = async () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };

  //Register
  const register = async (
    orgId,
    email,
    password,
    setStatus,
    setSubmitting,
    setErrors,
    setShowToast,
    history,
    resetForm
  ) => {
    const registerUserQuery = gql`
      mutation RegisterUser(
        $email: String!
        $organizationId: UUID!
        $password: String!
      ) {
        registerUser(
          input: {
            email: $email
            password: $password
            organizationId: $organizationId
          }
        ) {
          orgUser {
            createdAt
          }
        }
      }
    `;
    const variables = {
      email: email,
      password: password,
      organizationId: orgId,
    };

    request(endpoint, registerUserQuery, variables).then(
      (response) => {
        if (response) {
          /*if (response.data.message) {
            setStatus({success: false});
            setErrors({submit: response.data.message});
            setSubmitting(false);
          } else {
            setStatus({success: true});
            resetForm({})
            setShowToast(true)
          }*/
          history.push("/create-organization");
        }
      },
      (error) => {
        console.log(error);
        // setStatus({success: false});
        setErrors({
          submit:
            error === "Something went wrong"
              ? "We're having trouble connecting. Please try agian later"
              : error.message,
        });
        // setSubmitting(false);
      }
    );
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        const email = window.localStorage.getItem("email");
        const organizationId = window.localStorage.getItem("organizationId");

        if (accessToken && isValidToken(accessToken)) {
          // const userId  = jwt.verify(accessToken, JWT_SECRET);
          // console.log(userId)
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: true,
              user: {
                email: email,
                name: email,
                organizationId: organizationId,
              },
            },
          });
        } else {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("email");
          localStorage.removeItem("organizationId");
          delete axios.defaults.headers.common.Authorization;
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        return [500, { message: "Internal server error" }];
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
